import { createContext, useContext, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import toast from 'react-hot-toast'
import { AlertCircle, CheckCircle, XCircle, X } from 'react-feather'
//import { error } from 'jquery'

const LoggedIn = ({ t, name }) => {
    return (
        <div className="d-flex">
            <div className="me-1">
                <Avatar size="sm" color="success" icon={<CheckCircle size={12} />} />
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <h6>{name}</h6>
                    <X size={12} className="cursor-pointer" onClick={() => toast.dismiss(t.id)} />
                </div>
                <span>You have successfully logged in!</span>
            </div>
        </div>
    )
}

const LoggedOut = ({ t, name }) => {
    return (
        <div className="d-flex">
            <div className="me-1">
                <Avatar size="sm" color="warning" icon={<AlertCircle size={12} />} />
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <h6>{name}</h6>
                    <X size={12} className="cursor-pointer" onClick={() => toast.dismiss(t.id)} />
                </div>
                <span>You have successfully logged out!</span>
            </div>
        </div>
    )
}

const Error = ({ t, title, message }) => {
    return (
        <div className="d-flex">
            <div className="me-1">
                <Avatar size="sm" color="danger" icon={<XCircle size={12} />} />
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <h6>{title}</h6>
                    <X size={12} className="cursor-pointer" onClick={() => toast.dismiss(t.id)} />
                </div>
                <span>{message}</span>
            </div>
        </div>
    )
}

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    //const [errors, setErrors] = useState([])
    const navigate = useNavigate()

    const getUser = () => {
        console.log('obtaining user...')
        return axios
            .get('/api/user')
            .then((res) => {
                setUser(res.data.user)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    toast((t) => <Error t={t} title={error.response.data.message} message={error.message} />)
                    navigate('/login')
                }
            })
    }

    const login = ({ email, password }) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
            axios
                .post('/api/login', { email, password })
                .then((res) => {
                    if (res.status === 200) {
                        setUser(res.data.user)
                        navigate('/')
                        toast((t) => <LoggedIn t={t} name={res.data.name || res.data.email} />)
                    }
                })
                .catch((error) => {
                    // console.log('error = ', error)
                    if (error.response.status === 422) {
                        toast((t) => <Error t={t} title={error.response.data.message} message={error.message} />)
                    }
                })
        })
    }

    const logout = () => {
        axios.post('/api/logout').then(() => {
            toast((t) => <LoggedOut t={t} name={user?.name || user?.email} />)
            setUser(null)
            navigate('/login')
        })
    }

    return <AuthContext.Provider value={{ login, logout, user, getUser }}>{children}</AuthContext.Provider>
}

export default function useAuthContext() {
    return useContext(AuthContext)
}
